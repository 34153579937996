<template>
  <setup-table
    :title="`Funding Source`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <funding-source-form
        ref="fundingSourceForm"
        :value="editedItem"
        :budgetCodes="budgetCodes"
        :tripTypes="tripTypes"
        :users="users"
        @submit="saveItem"
        @closeForm="closeForm"
      ></funding-source-form>
    </template>

    <template #[`item.type`]="{ item }">
      <p class="mb-0">{{ types[item.type - 1] }}</p>
    </template>
    <template #[`item.incOnFinance`]="{ item }">
      <v-icon small>{{ item.incOnFinance ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.locationSpecific`]="{ item }">
      <v-icon small>{{ item.locationSpecific ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.active`]="{ item }">
      <v-icon small>{{ item.active ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.budgetCode`]="{ item }">
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small left v-if="item.isBudgetCodeDeleted" v-bind="attrs" v-on="on" color="red">
              mdi-alert-circle
            </v-icon>
          </template>
          <span v-if="item.isBudgetCodeDeleted">{{ removedBudgetCodeMessage }}</span>
        </v-tooltip>
        {{ item.budgetCode }}
      </div>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addBudgetCodeStrings } from '@/util';
import {
  GET_FUNDING_SOURCES,
  SAVE_FUNDING_SOURCE,
  DELETE_FUNDING_SOURCES,
} from '@/store/modules/FundingSource/actions';
import { GET_BUDGET_CODES } from '@/store/modules/BudgetCode/actions';
import { GET_TRIP_TYPES } from '@/store/modules/TripType/actions';
import SetupTable from './SetupTable.vue';
import FundingSourceForm from './FundingSourceForm.vue';
import { removedBudgetCodeMessage } from '@/util/messages';

export default {
  name: 'FundingSourceSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, FundingSourceForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      headers: [
        { text: 'Funding Source', value: 'name' },
        { text: 'Funding Approver', value: 'approverUsername' },
        // { text: 'Approver', value: 'approver' },
        { text: 'Use', value: 'type' },
        { text: 'Budget Code', value: 'budgetCode' },
        { text: 'Sch Fin', value: 'incOnFinance' },
        { text: 'Loc Spec', value: 'locationSpecific' },
        { text: 'Active', value: 'active' },
      ],
      types: ['SingleNoLoc', 'SingleLoc', 'Multiple', 'None'],
      budgetCodes: [],
      removedBudgetCodeMessage,
    };
  },
  computed: {
    ...mapGetters('fundingSource', ['fundingSources']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('user', ['users']),
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('fundingSource', [GET_FUNDING_SOURCES, SAVE_FUNDING_SOURCE, DELETE_FUNDING_SOURCES]),
    ...mapActions('budgetCode', [GET_BUDGET_CODES]),
    ...mapActions('tripType', [GET_TRIP_TYPES]),
    ...mapActions('user', ['getUsers']),
    async fetchItems() {
      await this.getFundingSources({ forTable: true });
      this.items = this.fundingSources;
      const codes = await this.getBudgetCodes();
      this.budgetCodes = addBudgetCodeStrings(codes);
      await this.getTripTypes();
      await this.getUsers();
      this.loading = false;
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0, name: '' };
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveFundingSource(item);
        if (r && r.id) {
          this.$myalert.success('Funding Source saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
          return true;
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      return false;
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Funding Sources' : 'this Funding Source'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteFundingSources(items.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${items.length > 1 ? 'Funding Sources' : 'Funding Source'} deleted`);
          this.showForm = false;
          this.fetchItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
